<script>
import Header from "@/components/Header.vue"
import Card from "@/components/Card.vue"
import CardLine from "@/components/CardLine.vue"
import Select from "@/components/Select.vue"
import Loader from '@/components/Loader.vue'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from 'axios';

export default {
    data() {
        return {
            loader: true,
            properties: [
                {
                    id: 1,
                    imageUrl: "https://projetaronline.com/wp-content/uploads/2022/11/casa-pequena-planta-baixa-0.png",
                    status: true,
                    title: "Casa de 24m2 no Centro, Próximo a mercado",
                    price: 100000,
                    location: "Santa Teresa - ES"
                },
                {
                    id: 1,
                    imageUrl: "https://projetaronline.com/wp-content/uploads/2022/11/casa-pequena-planta-baixa-0.png",
                    status: false,
                    title: "Casa de 24m2 no Centro, Próximo a mercado",
                    price: 100000,
                    location: "Santa Teresa - ES"
                },
                {
                    id: 1,
                    imageUrl: "https://projetaronline.com/wp-content/uploads/2022/11/casa-pequena-planta-baixa-0.png",
                    status: true,
                    title: "Casa de 24m2 no Centro, Próximo a mercado",
                    price: 100000,
                    location: "Santa Teresa - ES"
                },
                {
                    id: 1,
                    imageUrl: "https://projetaronline.com/wp-content/uploads/2022/11/casa-pequena-planta-baixa-0.png",
                    status: false,
                    title: "Casa de 24m2 no Centro, Próximo a mercado",
                    price: 100000,
                    location: "Santa Teresa - ES"
                },
                {
                    id: 1,
                    imageUrl: "https://projetaronline.com/wp-content/uploads/2022/11/casa-pequena-planta-baixa-0.png",
                    status: false,
                    title: "Casa de 24m2 no Centro, Próximo a mercado",
                    price: 100000,
                    location: "Santa Teresa - ES"
                },
                {
                    id: 1,
                    imageUrl: "https://projetaronline.com/wp-content/uploads/2022/11/casa-pequena-planta-baixa-0.png",
                    status: false,
                    title: "Casa de 24m2 no Centro, Próximo a mercado",
                    price: 100000,
                    location: "Santa Teresa - ES"
                },
                {
                    id: 1,
                    imageUrl: "https://projetaronline.com/wp-content/uploads/2022/11/casa-pequena-planta-baixa-0.png",
                    status: false,
                    title: "Casa de 24m2 no Centro, Próximo a mercado",
                    price: 100000,
                    location: "Santa Teresa - ES"
                },
                {
                    id: 1,
                    imageUrl: "https://projetaronline.com/wp-content/uploads/2022/11/casa-pequena-planta-baixa-0.png",
                    status: false,
                    title: "Casa de 24m2 no Centro, Próximo a mercado",
                    price: 100000,
                    location: "Santa Teresa - ES"
                },
                {
                    id: 1,
                    imageUrl: "https://projetaronline.com/wp-content/uploads/2022/11/casa-pequena-planta-baixa-0.png",
                    status: false,
                    title: "Casa de 24m2 no Centro, Próximo a mercado",
                    price: 100000,
                    location: "Santa Teresa - ES"
                },
            ],
            view: 'list-properties',
            editPropertieForm: {
                title: "",
                price: 0,
                location: "",
                description: "",
                category: "",
            },
            files: [],
            propertiesCategories: [
                {
                    id: 2,
                    name: 'Casas e Apartamentos',
                    value: 'casas-apartamentos'
                },
                {
                    id: 3,
                    name: 'Chácaras',
                    value: 'chacaras'
                },
                {
                    id: 4,
                    name: 'Sítios',
                    value: 'sitios'
                },
                {
                    id: 5,
                    name: 'Lotes',
                    value: 'lotes'
                },
                {
                    id: 6,
                    name: 'Terrenos',
                    value: 'terrenos'
                },
                {
                    id: 7,
                    name: 'Pontos Comerciais',
                    value: 'pontos-comerciais'
                },
            ],
        }
    },
    created() {
        const userToken = localStorage.getItem('UAUTHTKKEY');

        if (!userToken) {
            this.$router.push('/login')
        }

        this.getProperties();
    },
    methods: {
        notify(text, type) {
            toast(text, {
                "type": type == 'info' ? 'info' : type == 'warning' ? 'warning' : type == 'error' ? 'error' : type == 'success' ? 'success' : 'default',
            });
        },
        handleFileUpload(event) {
            this.files = Array.from(event.target.files);
        },
        selectCreatePropertie(value) {
            this.editPropertieForm.category = value;
        },
        async postImage(image, id) {
            await axios.post('https://files.joseguilhermeimoveis.com.br/upload.php', {
                file: image
            }, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
            .then(res => {
                this.$axios.post('media', {
                    properties_id: id,
                    title: res.data.fileName,
                    url: `https://files.joseguilhermeimoveis.com.br/uploads/${res.data.fileName}`
                })
                .then(res => {
                    console.log(res);
                    console.log('Arquivo enviado!');
                })
                .catch(err => {
                    console.log(err)
                    console.log('Erro ao enviar arquivo!');
                })
            })
            .catch(err => {
                return false;
            })
        },
        addPropertiePost() {
            if (!this.editPropertieForm.title || !this.editPropertieForm.price || !this.editPropertieForm.location || !this.editPropertieForm.description || !this.editPropertieForm.category) {
                return this.notify('Preencha todos os campos para adicionar uma propriedade', 'error');
            }

            this.loader = true;

            this.$axios.post('properties', this.editPropertieForm)
            .then(res => {
                if (this.files.length > 0) {
                    this.files.forEach(file => {
                        this.postImage(file, res.data.data.id);
                    });
                }

                this.notify('Propriedade adicionada com sucesso!', 'success');

                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch(err => {
                this.notify('Ocorreu um erro durante a criação da propriedade e não foi possível finalizar!');

                return this.loader = false;
            });
        },
        editPropertie(data) {
            this.editPropertieForm = data;
            this.editPropertieForm.category = data.category;
            
            this.view = 'edit-propertie';
        },
        deleteMediaPropertie(id) {
            this.loader = true;

            this.$axios.delete(`media/${id}`)
            .then(res => {
                this.editPropertieForm.medias = this.editPropertieForm.medias.filter((item) => item.id !== id);
                
                this.notify('Imagem removida com sucesso!', 'success');

                this.loader = false;
            })
            .catch(err => {
                this.notify('Ocorreu um erro ao tentar remover a imagem da propriedade!', 'error');

                return this.loader = false;
            });
        },
        editPropertieAction() {
            this.loader = true;

            this.$axios.put(`properties/${this.editPropertieForm.id}`, this.editPropertieForm)
            .then(res => {
                if (this.files.length > 0) {
                    this.files.forEach(file => {
                        this.postImage(file, this.editPropertieForm.id);
                    });
                }

                this.notify('Propriedade atualizada com sucesso!', 'success');
                
                setTimeout(() => {
                    window.location.reload();
                }, 5000);
            })
            .catch(err => {
                this.notify('Ocorreu um erro ao tentar atualizar a propriedade!', 'error');

                this.loader = false;
            });
        },
        getProperties() {
            this.loader = true;

            this.$axios.get('/properties')
            .then(res => {
                console.log(res.data.data);
                this.properties = res.data.data;
                this.loader = false;
            })
            .catch(err => {
                this.loader = false;
            })
        },
        updateStatus(propertie) {
            this.$axios.put(`/properties/${propertie.id}`, {
                is_active: propertie.is_active == 1 ? 0 : 1
            })
            .then(res => {
                this.notify('Visibilidade da propriedade foi alterada com sucesso!', 'success');

                window.location.reload();
            })
            .catch(err => {
                this.notify('Ocorreu ume erro ao tentar alterar a visibilidade da propriedade!', 'error');
                this.loader = false;
            });
        }
    },
    components: { Header, Card, CardLine, Select, Loader }
}
</script>

<template>
    <Loader v-if="loader" />

    <section class="bg-dash">
        <div class="list-properties">
            <p v-if="view != 'list-properties'" @click="view = 'list-properties'" class="return-to-page">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
                </svg>

                Voltar para a listagem de imoveis
            </p>

            <Header :title="view == 'list-properties' ? 'Todos os imóveis' : view == 'add-propertie' ? 'Adicionar novo imóvel' : 'Editar imóvel'"></Header>

            <button v-if="view == 'list-properties'" @click="view = 'add-propertie'" class="add-propertie">Adicionar novo imóvel</button>

            <!-- Listar propriedades -->
            <div v-if="view == 'list-properties' && !loader" class="properties">
                <div v-for="propertie in properties" class="propertie">
                    <CardLine :imageUrl="propertie.medias.length > 0 ? propertie.medias[0].url : false" :title="propertie.title" :status="propertie.is_active" :location="propertie.location"  :price="propertie.price" :data="propertie" @edit="editPropertie" @updateStatus="updateStatus(propertie)" />
                </div>
            </div>

            <!-- Adicionar propriedade -->
            <div v-if="view == 'add-propertie'" class="bg-add-propertie">
                <label for="title" class="label">Digite o nome do imóvel</label>
                <input type="text" name="title" placeholder="Apartamento no centro com 24m2" v-model="editPropertieForm.title" class="input mb-2" />

                <Select class="select-category mb-2" :options="propertiesCategories" :selected="1" @handleChange="selectCreatePropertie" />

                <label for="title" class="label">Digite o valor do imóvel</label>
                <input type="number" name="title" placeholder="180000.00" v-model="editPropertieForm.price" class="input mb-2" />

                <label for="title" class="label">Digite a localização do imóvel</label>
                <input type="text" name="title" placeholder="Santa Teresa - ES" v-model="editPropertieForm.location" class="input mb-2" />

                <label for="title" class="label">Adicione uma descrição mais detalhada sobre o imóvel</label>
                <input type="text" name="title" placeholder="Esse completo imóvel contém 3 quartos, área de churrasco, cozinha, sala de estar..." v-model="editPropertieForm.description" class="input mb-2" />

                <label for="title" class="label">Selecione as imagens do imóvel</label>
                <input type="file" multiple class="input" @change="handleFileUpload">

                <button class="button-add-propertie" @click="addPropertiePost">
                    Adicionar imóvel
                </button>
            </div>

            <!-- Editar propriedade -->
            <div v-if="view == 'edit-propertie'" class="bg-add-propertie">
                <label for="title" class="label">Digite o nome do imóvel</label>
                <input type="text" name="title" placeholder="Apartamento no centro com 24m2" v-model="editPropertieForm.title" class="input mb-2" />

                <Select class="select-category mb-2" :options="propertiesCategories" :selected="1" @handleChange="selectCreatePropertie" />

                <label for="title" class="label">Digite o valor do imóvel</label>
                <input type="number" name="title" placeholder="180000.00" v-model="editPropertieForm.price" class="input mb-2" />

                <label for="title" class="label">Digite a localização do imóvel</label>
                <input type="text" name="title" placeholder="Santa Teresa - ES" v-model="editPropertieForm.location" class="input mb-2" />

                <label for="title" class="label">Adicione uma descrição mais detalhada sobre o imóvel</label>
                <input type="text" name="title" placeholder="Esse completo imóvel contém 3 quartos, área de churrasco, cozinha, sala de estar..." v-model="editPropertieForm.description" class="input mb-2" />

                <label for="title" class="label">Adicione imagens ao imóvel</label>
                <input type="file" multiple class="input" @change="handleFileUpload">

                <div class="edit-properties-list-images" v-if="editPropertieForm.medias.length > 0">
                    <figure v-for="(media, index) in editPropertieForm.medias">
                        <img :src="media.url" alt="">

                        <div @click="deleteMediaPropertie(media.id)" class="delete-image">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                            </svg>
                        </div>
                    </figure>
                </div>

                <button class="button-add-propertie" @click="editPropertieAction">
                    Editar imóvel
                </button>
            </div>
        </div>
    </section>
</template>

<style scoped>
.bg-dash {
    width: 100%;
    height: 100%;
    padding: 20px 50px;
}

.properties {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 30px;
}

.propertie {
    width: 100%;
    margin-bottom: 14px;
}

.card {
    margin-bottom: 30px;
}

.add-propertie {
    padding: 16px 0px;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    background-color: #fec450;
    cursor: pointer;
    font-weight: 500;
    color: #FFF;
    transition: .2s;
    margin-top: 20px;
    width: 250px;
}

.add-propertie:hover {
    background-color: #fcc046;
    transition: .2s;
}

.bg-add-propertie {
    margin-top: 30px;
}

.button-add-propertie {
    width: 100%;
    padding: 16px 0px;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    background-color: #fec450;
    cursor: pointer;
    font-weight: 500;
    color: #FFF;
    transition: .2s;
    margin-top: 20px;
}

.button-add-propertie:hover {
    background-color: #fcc046;
    transition: .2s;
}

.return-to-page {
    display: flex;
    margin-bottom: 30px;
    cursor: pointer;
    color: #FF9F3F;
}

.return-to-page .icon {
    width: 20px;
    margin-right: 10px;
}

.edit-properties-list-images {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 20px
}

.edit-properties-list-images figure {
    position: relative;
}

.edit-properties-list-images img {
    max-width: 300px;
    margin: 5px;
}

.edit-properties-list-images .delete-image {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.edit-properties-list-images .delete-image .icon {
    width: 20px;
    color: rgb(254, 65, 65);
    transition: .2s;
}

.edit-properties-list-images .delete-image:hover .icon {
    color: rgb(238, 26, 26);
    transition: .2s;
}
</style>