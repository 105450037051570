<script>
import Header from '@/components/Header.vue'
import Select from '@/components/Select.vue'
import Card from '@/components/Card.vue'
import Loader from '@/components/Loader.vue'

export default {
    data() {
        return {
            loader: true,
            propertiesCategories: [
                {
                    id: 1,
                    name: 'Todos',
                    value: 'todos'
                },
                {
                    id: 2,
                    name: 'Casas e Apartamentos',
                    value: 'casas-apartamentos'
                },
                {
                    id: 3,
                    name: 'Chácaras',
                    value: 'chacaras'
                },
                {
                    id: 4,
                    name: 'Sítios',
                    value: 'sitios'
                },
                {
                    id: 5,
                    name: 'Lotes',
                    value: 'lotes'
                },
                {
                    id: 6,
                    name: 'Terrenos',
                    value: 'terrenos'
                },
                {
                    id: 7,
                    name: 'Pontos Comerciais',
                    value: 'pontos-comerciais'
                },
            ],
            properties: [],
            selectedCategory: 'todos',
        }
    },
    created() {
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.get('categoria')) {
            this.selectedCategory = urlParams.get('categoria');
        }

        this.getProperties();
    },
    methods: {
        getProperties() {
            this.loader = true;

            this.$axios.get(`/properties/public?category=${this.selectedCategory}`)
            .then(res => {
                this.properties = res.data.data;
                console.log(res.data.data);
                this.loader = false;
            })
            .catch(err => {
                this.loader = false;
            });
        },
        callToWppPropertie(propertie) {
            return window.open(`https://wa.me/+5527999746179?text=Olá, José Guilherme. Tenho interesse no imóvel ${propertie.title} - ${propertie.location} - ${parseFloat(propertie.price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`, '_blank');
        },
        alterCategory(value) {
            this.selectedCategory = value;
            return this.getProperties();
        }
    },
    components: { Header, Select, Card, Loader }
}
</script>

<template>
    <Loader v-if="loader" />

    <section class="background-properties">
        <Header title="Imóveis disponíveis" />

        <Select class="select-category" :options="propertiesCategories" :selected="1" @handleChange="alterCategory" />

        <div v-if="!loader" class="properties-list">
            <Card v-for="(propertie, index) in properties" :propertieId="propertie.id" :title="propertie.title" :city="propertie.location" :price="propertie.price" :imageUrl="propertie.medias.length > 0 ? propertie.medias[0].url : false" @callToWpp="callToWppPropertie(propertie)" class="card"></Card>
        </div>
    </section>
</template>

<style scoped>
    .background-properties {
        padding: 20px 50px;
    }

    .select-category {
        margin-top: 20px;
        width: 400px;
        max-width: 100%;
    }

    .properties-list {
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 30px;
        min-height: 50vh;
    }

    .card {
        margin-bottom: 40px;
        margin-right: 10px
    }

    @media screen and (max-width:728px) {
        .background-properties {
            padding: 20px 30px;
        }

        .select-category {
            width: 100%;
        }

        .card {
            margin-right: 0px;
        }
    }
</style>