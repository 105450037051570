<script>
export default {
    props: ["title", "city", "price", "propertieId", "imageUrl"]
}
</script>

<template>
    <section class="card">
        <figure @click="this.$router.push(`/imoveis/${propertieId}`)" class="bg-card-figure">
            <img v-if="imageUrl" :src="imageUrl" alt="">
            <img v-else src="@/assets/img/not-image.png" alt="">
        </figure>

        <article class="bg-card-infos">
            <div class="card-infos">
                <h2 @click="this.$router.push(`/imoveis/${propertieId}`)">{{ title }}</h2>
                <p @click="this.$router.push(`/imoveis/${propertieId}`)">{{ city }}</p>
            </div>
            
            <div class="card-infos-wpp" @click="this.$emit('callToWpp')">
                <img src="@/assets/img/whatsapp-icon.svg" alt="">
            </div>
        </article>

        <section class="bg-price" @click="this.$router.push(`/imoveis/${propertieId}`)">
            {{ parseFloat(price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
        </section>
    </section>
</template>

<style scoped>
    .card {
        width: 312px;
        min-height: 322px;
        border: 1px solid rgb(213, 221, 232);
        border-radius: 10px;
    }

    .bg-card-figure {
        width: 100%;
        height: 185px;
        overflow: hidden;
        position: relative;
    }

    .bg-card-figure img {
        width: 100%;
        min-height: 185px;
        height: auto;
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px 10px 0px 0px;
        cursor: pointer;
    }  

    .bg-card-infos {
        padding: 16px 12px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .bg-card-infos .card-infos h2 {
        font-size: 18px;
        font-weight: 600;
        color: #353535;
        margin-bottom: 5px;
        cursor: pointer;
    }

    .bg-card-infos .card-infos p {
        cursor: pointer;
    }
    
    .bg-card-infos .card-infos-wpp img {
        width: 24px;
        cursor: pointer;
    }

    .bg-price {
        padding: 0px 12px;
        font-size: 18px;
        font-weight: 600;
        color: #353535;
        margin-top: 10px;
        margin-bottom: 30px;
        cursor: pointer;
    }

    @media screen and (max-width: 682px) {
        .card {
            width: 100%;
        }
    }
</style>