<script>
export default {
    methods: {
        callToWpp() {
            return window.open(`https://wa.me/+5527999746179?text=Olá, José Guilherme. Encontrei o seu contato no site da José Guilherme Imóveis, pode me ajudar?`, '_blank');
        }
    }
}
</script>

<template>
    <section class="bg-contact">
        <button @click="callToWpp" class="call-to-action-button">
            Entrar em contato pelo WhatsApp
        </button>
    </section>
</template>

<style scoped>
    .bg-contact {
        width: 100%;
        display: flex;
        justify-content: center;
        min-height: 75vh;
    }

    .call-to-action-button {
        background-color: #005F41;
        padding: 15px 0px;
        width: 400px;
        border: 1px solid #005F41;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 550;
        color: white;
        cursor: pointer;
        transition: .3s;
        margin-top: 25px;
        height: 50px;
    }

    .call-to-action-button:hover {
        background-color: #067652;
        transition: .3s;
    }
</style>