<script>
import axios from 'axios';

export default {
  data() {
    return {
      hasUserLogged: false
    }
  },
  setup() {
    document.title = "O imóvel que você procura com a qualidade que você quer | José Guilherme Imóveis"
  },
  created() {
    if (localStorage.getItem('UAUTHTKKEY')) {
      this.hasUserLogged = true;
    }

    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('UAUTHTKKEY');
  
        if (!token) {
          //this.$router.push('/login')
        }
  
        config.headers.Authorization = `Bearer ${token}`;
  
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
    (response) => {
      return response;
    },
      async (error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('UAUTHTKKEY');

          await router.push('/login');

          return Promise.reject();
        }

        return Promise.reject(error);
      }
    );
  },
}
</script>

<template>
  <section class="principal-bg">
    <section v-if="this.$route.path != '/login'"  class="top-nav-bar">
      <figure>
        <img src="./assets/img/joseguilhermeimoveislogo.svg" alt="">
      </figure>

      <nav>
        <router-link :class="[this.$route.path == '/' ? 'link-active' : '', 'link']" to="/">
          Página inicial
        </router-link>
        
        <router-link :class="[this.$route.path == '/imoveis' ? 'link-active' : '', 'link']" to="/imoveis">
          Imóveis
        </router-link>
        
        <router-link :class="[this.$route.path == '/contato' ? 'link-active' : '', 'link']" to="/contato">
          Contato
        </router-link>
        
        <router-link v-if="hasUserLogged" :class="[this.$route.path == '/dashboard' ? 'link-active' : '', 'link']" to="/dashboard">
          Dashboard
        </router-link>
      </nav>
    </section>

    <div class="main">
      <router-view />

      <footer v-if="this.$route.path != '/login' && this.$route.path != '/dashboard'" >
        <p>Todo os direitos reservados &copy; José Guilherme Imóveis</p>
        <p>Desenvolvido por Cometa Code</p>
      </footer>
    </div>
  </section>
</template>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    text-decoration: none;
    user-select: none;
    font-family: 'Montserrat';
  }

  *::-webkit-scrollbar {
    width: 8px;               /* width of the entire scrollbar */
  }

  *::-webkit-scrollbar-track {
    background: transparent;        /* color of the tracking area */
  }

  *::-webkit-scrollbar-thumb {
    background-color: #FFCC66;    /* color of the scroll thumb */
    border-radius: 10px;       /* roundness of the scroll thumb */
    border: 2px solid #fff;  /* creates padding around scroll thumb */
  }

  .label {
    margin-bottom: 20px;
    font-size: 16px;
  }

  .input {
    width: 100%;
    padding: 14px 14px;
    border: none;
    border: 1px solid rgb(82, 82, 82);
    border-radius: 10px;
    font-size: 16px;
  }

  .mb-2 {
    margin-bottom: 20px;
  }


  /* APP */

  .principal-bg {
    max-width: 1600px;
    margin: 0 auto;
  }

  .top-nav-bar {
    width: 100%;
    padding: 8px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .top-nav-bar nav .link {
    color: #474747;
    font-weight: 500;
    margin-left: 20px;
    transition: .3s;
  }

  .top-nav-bar nav .link:hover {
    color: #141414;
    transition: .3s;
  }

  .top-nav-bar nav .link-active {
    color: #FF9F3F;
  }

  .top-nav-bar nav .link-active:hover {
    color: #f78c21;
    transition: .3s;
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    overflow: hidden;
  }

  .main footer {
    width: 100%;
    text-align: center;
    padding: 20px 0px;
    background-color: #FFFEFC;
    border-top: 1px solid rgb(238, 238, 238);
  }

  .main footer p {
    font-size: 16px;
    padding: 2px 0px;
  }

  @media screen and (max-width: 768px) {
    .top-nav-bar {
      padding: 8px 20px;
      flex-direction: column;
    }

    .top-nav-bar nav .link {
      margin: 0px 10px 0px 10px;
    }

    .top-nav-bar figure img {
      width: 160px;
      margin-bottom: 8px;
    }
  }
</style>
