<script>
export default {
    props: {
        title: {
            type: String
        }
    }
}
</script>

<template>
    <section class="bg-category">
        <header>
            <h2>{{ title }}</h2>
            <p @click="this.$router.push('/imoveis')">
                Clique para ver todos 

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                </svg>
            </p>
        </header>

        <section class="bg-slot">
            <slot></slot>
        </section>
    </section>
</template>

<style scoped>
    .bg-category {
        width: 100%;
        margin-top: 60px;
    }

    .bg-category header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 50px;
    }

    .bg-category header h2 {
        font-size: 22px;
        font-weight: 500;
        color: #474747;
    }

    .bg-category header p { 
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 400;
        cursor: pointer;
    }

    .bg-category header p .icon {
        width: 20px;
        margin-left: 10px;
    }

    .bg-slot {
        width: 2000px;
        height: auto;
        padding: 40px 0px 0px 50px;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        overflow-x: hidden;
    }

    @media screen and (max-width: 768px) {
        .bg-category {
            margin-top: 40px;
        }

        .bg-category header {
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            margin-left: 20px;
        }

        .bg-category header h2 {
            margin-bottom: 10px;
        }

        .bg-slot {
            padding: 40px 0px 0px 20px;
        }
    }
</style>