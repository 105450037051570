<script>
export default {
    props: {
        data: {
            type: Number
        },
        imageUrl: {
            type: String,
        },
        status: {
            type: Boolean
        },
        title: {
            type: String
        },
        price: {
            type: Number
        },
        location: {
            type: String
        },
    }
}
</script>

<template>
    <section class="bg-card-line">
        <section class="bg-card-line-infos">
            <figure>
                <img v-if="imageUrl" :src="imageUrl" alt="">
                <img v-else src="@/assets/img/not-image.png" alt="">
            </figure>

            <section class="infos">
                <div class="line">
                    <p class="rounded gray">{{ title.slice(0, 40) }}</p>
                    <p class="rounded gray"> {{ location }} </p>
                </div>
                <div class="line">
                    <p class="rounded yellow"> {{ parseFloat(price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }} </p>
                    <p @click="this.$emit('updateStatus')" :class="[status ? 'active' : 'deactivate', 'rounded']"> {{ status ? 'Ativo' : 'Inativo'  }} </p>
                </div>
            </section>
        </section>

        <section class="actions">
            <div @click="this.$emit('edit', data)">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                </svg>
            </div>
        </section>
    </section>
</template>

<style scoped>
.bg-card-line {
    width: 100%;
    height: auto;
    padding: 15px 15px;
    border-radius: 10px;
    border: 1px solid rgb(211, 211, 211);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bg-card-line-infos {
    display: flex;
}

.bg-card-line figure img {
    width: 100px;
}

.infos {
    margin-left: 30px;
}

.infos p {
    margin: 0px 10px 5px 0px;
}

.line {
    display: flex;
}

.active {
    background-color: rgb(116, 244, 116);
    border: 1px solid rgb(30, 235, 19);
    cursor: pointer;
}

.deactivate {
    background-color: rgb(253, 143, 143);
    border: 1px solid rgb(235, 44, 19);
    cursor: pointer;
}

.yellow {
    background-color: rgb(247, 228, 150);
    border: 1px solid rgb(238, 219, 9);
}

.gray {
    background-color: rgb(244, 244, 244);
    border: 1px solid rgb(181, 181, 181);
}

.rounded {
    padding: 2px 10px;
    border-radius: 100px;
    font-size: 14px;
}

.actions .icon {
    width: 20px;
    cursor: pointer;
    color: rgb(93, 93, 215);
}

@media screen and (max-width:800px) {
    .bg-card-line {
        flex-direction: column;
    }

    .line {
        flex-direction: column;
    }

    .bg-card-line-infos {
        flex-direction: column;
        align-items: center;
    }
}
</style>