<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        }
    }
}
</script>

<template>
    <section class="bg-header">
        <h1>
            {{ title }}
        </h1>
    </section>
</template>

<style scoped> 
    .bg-header {
        width: 100%;
    }

    .bg-header h1 {
        font-size: 22px;
        font-weight: 600;
        color: #FF9F3F;
    }
</style>