<script>
import Loader from "@/components/Loader";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
    data() {
        return {
            formLogin: {
                email: "",
                password: "",
            },
            loader: false,
        }
    },
    created() {
        const userToken = localStorage.getItem('UAUTHTKKEY');

        if (userToken) {
            this.$router.push('/inicio')
        }
    },
    methods: {
        notify(text, type) {
            toast(text, {
                "type": type == 'info' ? 'info' : type == 'warning' ? 'warning' : type == 'error' ? 'error' : type == 'success' ? 'success' : 'default',
            });
        },
        login() {
            this.loader = true;

            if (!this.formLogin.email || !this.formLogin.password) {
                this.notify('Preencha todos os campos para prosseguir com o login!', 'error');
                this.loader = false;
                return;
            }

            this.notify('Validando dados de acesso!', 'info');

            this.$axios.post('user/login', {
                email: this.formLogin.email,
                password: this.formLogin.password
            })
            .then(res => {
                this.notify(res.data.message, 'success');
                localStorage.setItem("UAUTHTKKEY", res.data.data.token);
                setTimeout(() => {
                    this.$router.push('/dashboard');
                }, 1000);
            })
            .catch(err => {
                this.loader = false;
                return this.notify('Credenciais incorretas ou usuário não cadastrado!', 'error');
            });
        }
    },
    components: { Loader }
}
</script>

<template>
    <Loader v-if="loader" />

    <section class="bg-login">
        <figure>
            <img src="@/assets/img/joseguilhermeimoveislogo.svg" alt="">
        </figure>

        <section class="forms-login">
            <label class="label" for="email">Digite o seu e-mail</label>
            <input type="email" name="email" v-model="formLogin.email" class="input mb-2" placeholder="email@email.com">

            <label class="label" for="password">Digite o seu e-mail</label>
            <input type="password" name="password" v-model="formLogin.password" class="input mb-2" placeholder="●●●●●●●●●●●●">

            <button class="button" @click="login">
                Fazer login
            </button>
        </section>
    </section>
</template>

<style scoped>
.bg-login {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bg-login figure {
    margin-bottom: 30px;
}

.forms-login {
    width: 500px;
    max-width: 98%;
    display: flex;
    flex-direction: column;
}

.button {
    padding: 16px 0px;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    background-color: #fec450;
    cursor: pointer;
    font-weight: 500;
    color: #FFF;
    transition: .2s;
}

.button:hover {
    background-color: #fcc046;
    transition: .2s;
}
</style>