<script>
import Header from "@/components/Header.vue"
import Loader from '@/components/Loader.vue'

export default {
    data() {
        return {
            selectedImage: null,
            loader: true,
            propertie: null,
        }
    },
    created() {
        this.getPropertie();
    },
    methods: {
        getPropertie() {
            this.loader = true;

            this.$axios.get(`/properties/unic/${this.$route.params.id}`)
            .then(res => {
                console.log(res.data.data);
                this.propertie = res.data.data;
                this.loader = false;
            })
            .catch(err => {
                this.$router.push('/imoveis');
            });
        },
        openImage(url) {
            this.selectedImage = url
        },
        callToWppPropertie() {
            return window.open(`https://wa.me/+5527999746179?text=Olá, José Guilherme. Tenho interesse no imóvel ${this.propertie.title} - ${this.propertie.location} - ${parseFloat(this.propertie.price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} - https://joseguilhermeimoveis.com.br/imoveis/${this.propertie.id}`, '_blank');
        },
    },
    components: { Header, Loader }
}
</script>

<template>
    <Loader v-if="loader" />

    <div v-if="selectedImage" @click="selectedImage = null" class="open-image">
        <div class="close-image"></div>
        <img :src="selectedImage" alt="">
    </div>

    <section v-if="!loader" class="bg-propertie">
        <Header title="Detalhes do imóvel" />

        <button @click="callToWppPropertie" class="call-to-action-button">
            Tenho interesse nesse imóvel
        </button>

        <div class="bg-propertie-infos">
        
            <section class="propertie-infos">
                <p>Título: <span>{{ propertie.title }}</span></p>
                <p>Localização: <span>{{ propertie.location }}</span></p>
                <p>Preço: <span>{{ parseFloat(propertie.price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span></p>
                <p>Categoria: <span>{{ propertie.category == 'casas-apartamentos' ? 'Casas e Apartamentos' : propertie.category == 'chacaras' ? 'Chácaras' : propertie.category == 'sitios' ? 'Sitios' : propertie.category == 'lotes' ? 'Lotes' : propertie.category == 'terrenos' ? 'Terrenos' : propertie.category == 'pontos-comerciais' ? 'Pontos Comerciais' : 'Categoria' }}</span></p>
                <p>Código do imóvel: <span>{{ propertie.id }}</span></p>
                <p>Descrição: <span>{{ propertie.description }}</span></p>
            </section>

            <section v-if="propertie.medias.length > 0" class="propertie-images">
                <img v-for="(media, index) in propertie.medias" :src="media.url" @click="openImage(media.url)" alt="">
            </section>
        </div>
    </section>
</template>

<style scoped>
.bg-propertie {
    width: 100%;
    padding: 20px 50px;
}

.bg-propertie-infos {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin-top: 30px;
}

.propertie-infos {
    width: 550px;
    padding: 10px 20px 10px 0px;
}

.propertie-infos p {
    font-size: 18px;
    font-weight: 550;
    padding: 0px 0px 20px 0px;
    text-align: justify;
}

.propertie-infos span {
    font-weight: 400;
}

.propertie-images {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-left: 30px;
}

.propertie-images img {
    max-width: 300px;
    margin: 0px 10px 5px 0px;
    cursor: pointer;
}

.call-to-action-button {
    background-color: #005F41;
    padding: 15px 0px;
    width: 400px;
    border: 1px solid #005F41;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 550;
    color: white;
    cursor: pointer;
    transition: .3s;
    margin-top: 25px;
}

.call-to-action-button:hover {
    background-color: #067652;
    transition: .3s;
}

.open-image {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.787);
    z-index: 20;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.open-image img {
    border: 1px solid #FFF;
    border-radius: 10px;
    max-width: 800px;
    width: 90%;
    position: relative;
}

@media screen and (max-width:860px) {
    .bg-propertie {
        padding: 20px 30px;
    }

    .bg-propertie-infos {
        display: flex;
        flex-direction: column;
    }

    .propertie-infos {
        width: 100%;
    }

    .propertie-images {
        padding-left: 0px;
    }

    .propertie-images img {
        margin: 10px 0px;
        max-width: 100%;
    }

    .call-to-action-button {
        width: 100%;
    }
}
</style>